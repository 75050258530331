@import '../../styles/config/variables';
@import 'src/styles/config/mixins';

.questionsGroupWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 18px;
  max-width: 100%;
  margin: 0 0 10px;

  @include media-breakpoint-up($xl) {
    margin: 16px 0 20px;
  }

  @media only screen and (max-height: 700px) {
    height: 150px;
    overflow: auto;
  }
}

.listItem {
  margin: 4px 4px;
  list-style-type: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.questionsGroupButton {
  padding: 4px 8px;
  transition: 0.25s ease-in-out;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  line-height: 15px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    opacity: 0.7;
  }
}

.buttonActive {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.buttonNotActive {
  background-color: $transparent;
  color: var(--quaternary-color);
  border-color: var(--quaternary-color);
}
