@import '../../styles/config/variables';

.listItem {
  margin-bottom: 7px;
  border-bottom: 1px solid rgba(var(--primary-colorA), 0.1);
}

.buttonNoStyle {
  background-color: $transparent;
  border: none;
  color: var(--quaternary-color);
  cursor: pointer;
  text-align: start;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.9px;
  padding: 12px 10px;
  border-left: 1px solid var(--primary-color);
  margin-bottom: 5px;
}
