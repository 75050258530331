@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

.showQuestions {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  transition: width 0.8s ease;

  @include media-breakpoint-size($md) {
    width: 33%;
  }
}

.questionsWrapper {
  background-color: rgba(var(--secondary-colorA), var(--alpha));
  color: $white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 75%;

  @include media-breakpoint-size($md) {
    background-color: var(--secondary-color);
    width: 33%;
    height: 100vh;
  }
}

.displayNone {
  display: none;
}

.header {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 18px 0;

  @include media-breakpoint-size($md) {
    justify-content: space-between;
  }
}

.headerSpace {
  padding: 75px 18px 0;
  @include media-breakpoint-up($md) {
    padding: 25px 18px 0;
  }
}

.questionsTitle {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  padding: 6px 6px;
  margin: 14px 2px;
  display: block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 42px;
    height: 4px;
    border-radius: 6px;
    background-color: var(--primary-color);
  }

  svg {
    display: block;
    height: 25px;
  }

  path {
    fill: var(--quaternary-color);
  }

  @include media-breakpoint-size($md) {
    border-left: 2px solid var(--primary-color);
    margin: 10px 2px;

    &::after {
      content: none;
    }

    svg {
      height: 37px;
    }
  }
}

.questionsLogo {
  background-color: var(--tertiary-color);
  padding: 10px 20px;
  border-radius: 100px 0px 0px 100px;
  display: inline-block;
  position: absolute;
  right: -18px;
  top: 24px;

  svg {
    display: block;
    height: 26px;
  }

  path {
    fill: $black;
  }

  @include media-breakpoint-up($lg) {
    padding: 12px 24px;
    top: 0;
    position: relative;
  }
}

.closeButton {
  background-color: $black;
  cursor: pointer;
}

.topRightLogo {
  height: 20px;
  fill: $white;
  display: block;

  @include media-breakpoint-up($lg) {
    height: 25px;
  }
}
