@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

.videoControls {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  transform: translateY(0px);
  padding: 5px;
  background-color: rgba(var(--secondary-colorA), 0.3);

  button {
    padding: 12px 5px;

    svg {
      width: auto;
      height: 22px;
      display: block;
    }
  }

  @include media-breakpoint-up($lg) {
    padding: 8px;

    button {
      padding: 10px 5px;

      svg {
        height: 26px;
      }
    }
  }
}

.btnCC {
  background-color: $transparent;
  cursor: pointer;
  border: 0;
  color: $white;
  font-size: 18px;
}

.btnCCActive {
  color: var(--primary-color);

  & > *:before {
    font-weight: bold !important;
  }
}

.btnPlay {
  background-color: $transparent;
  border: none;
  cursor: pointer;
}

.rightActions {
  display: flex;
}
.btnMute {
  background-color: $transparent;
  border: none;
  cursor: pointer;
}

.btnTyping {
  border: none;
  color: $white;
  padding: 7px;
  background-color: $transparent;
  font-size: 24px;
  cursor: pointer;
}

.activeIcon {
  path {
    fill: var(--primary-color);
  }
}

.btnTyping,
.btnMute {
  border-top: 1px solid rgba($white, 0.7);
}
