@import 'src/styles/config/variables';

.hideProgressBar {
  visibility: hidden;
}

.displayProgressBar {
  visibility: visible;
}

.videoProgressControls {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 4;
}

.progressInput::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: var(--primary-color);
  border: solid var(--primary-color);
}

.progressInput::-moz-range-thumb {
  border: solid var(--primary-color);
  border-radius: 50%;
  background: var(--primary-color);
}

progress {
  -webkit-appearance: none;
}

.progressInputDone {
  background-color: var(--primary-color);
  -webkit-appearance: none;
  height: 5px;
  bottom: 20%;
  position: absolute;
}

.progressInput {
  height: 5px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  background-color: $progressInput;
  margin: 0 auto;
}
