@import 'src/styles/config/variables';

.show {
  display: block;
}

.hide {
  display: none;
}

.loaderWrapper {
  height: 100%;
  width: 100%;
  min-height: 100vh;

  &.dark {
    background: black;
  }

  &.light {
    background-color: $transparent20;
  }
}

.loader {
  border: 4px solid $transparent10;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  border-top: 4px solid $black;
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
