@import 'src/styles/config/variables';

.speechActions {
  display: flex;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fade-in .3s ease-in .3s forwards;

  //For magnifier on IOS
  user-select: none;
  -webkit-user-select: none;
}

.speechButton {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  background: transparent;
  color: $white;
  cursor: pointer;
  position: relative;
  border: 2px solid $white;

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    width: 60%;
    height: 60%;
    border-radius: 50%;
  }

  .speechTooltip {
    svg {
      height: 16px;
    }
  }
}

.speechTooltip {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 10px 16px;
  border-radius: 20px;
  position: absolute;
  background-color: rgba($white, 0.6);
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
}

.speechTooltipText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $black;
  text-transform: uppercase;
  margin-left: 5px;
}

.speechButton:hover .speechTooltip {
  visibility: visible;
}

.speechBorder {
  .speechButton {
    border-color: var(--primary-color);
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}
