@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

.btnWebsite {
  background-color: var(--primary-color);
  padding: 11px 40px;
  color: $white;
  border-radius: 4px;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  transition: 0.3s ease-in-out;
  margin-left: 100px;

  &:hover {
    opacity: 0.7;
  }
}

.basicInfoRight {
  color: $transparent70;
  padding: 14px 30px;
  background-color: rgba(var(--secondary-colorA), 0.5);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: block;
  border-top: 1px solid rgba($white, 0.1);
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--quinary-color);
}

.socialButton {
  background-color: $transparent;
  border: none;
  vertical-align: top;
  cursor: pointer;

  svg {
    height: 20px;
  }

  path {
    fill: $white;
  }
}

.scrollable {
  max-height: 32vh;
  overflow: auto;
  margin: 10px 0;
}
