@import '../../styles/config/variables';

.typeInQuestionWrapper {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  position: relative;
  text-align-last: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 19px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: calc(100% - 64px);
    height: 1px;
    background-color: rgba($white, 0.3);
  }
}

.typeInQuestion {
  width: 100%;
  padding: 26px 20px;
  background-color: rgba(var(--secondary-colorA), 0.7);
  border-radius: 20px;
  border: none;
  border-width: 0 0 2px;
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--quinary-color);
  position: relative;
  &::placeholder {
    color: var(--quinary-color);
  }
}

.typeInQuestion:focus {
  outline: none;
}

.inputFieldSafari {
  -webkit-appearance: textfield; //Safari
}
