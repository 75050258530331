@import 'src/styles/config/variables';

.urlPopup {
  margin-top: 20px;
  text-align: center;
}

.externalUrl {
  display: inline-block;
  text-decoration: none;
  color: $black;
  background-color: rgba($white, 0.7);
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 18px;
}
