$black: #000000;
$white: #ffffff;
$dark_gray: #2e2d2d;
$transparent70: #ffffff80;
$transparent20: #000000ad;
$transparent10: #605a5acf;
$transparent5: #000000eb;
$transparent3: #ffffff8c;
$transparent: transparent;
$progressInput: #323232;

$background1: #00000087;

$red: #c54444;
$light_gray: #4c4c4cb8;
$blue: #2ca7ccd0;
$gray: #808080;

$yellow: #f7da0a;
$bright_yellow: #f7da0a61;

// Responsive variables
$xs: 500px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
