html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button:not(button[name='question']) {
  opacity: 0.7;
}

@font-face {
  font-family: 'fontello';
  src: url('./assets/icons/fontello.eot?60125946');
  src: url('./assets/icons/fontello.eot?60125946#iefix') format('embedded-opentype'),
    url('./assets/icons/fontello.woff2?60125946') format('woff2'),
    url('./assets/icons/fontello.woff?60125946') format('woff'),
    url('./assets/icons/fontello.ttf?60125946') format('truetype'),
    url('./assets/icons/fontello.svg?60125946#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
