@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

.basicInfoLeft {
  padding: 18px 24px 38px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgba(var(--secondary-colorA), 0.5);
  z-index: 1;
  max-width: 280px;

  @include media-breakpoint-up($lg) {
    min-width: 200px;
    max-width: none;
  }
}

.basicInfoName {
  color: var(--quinary-color);
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  margin: 0 0 5px;
  max-width: 225px;

  @include media-breakpoint-up($lg) {
    font-size: 28px;
    line-height: 32px;
  }
}

.buttonToggle {
  border: none;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  position: absolute;
  left: 50%;
  bottom: -14px;
  transform: translateX(-50%);
  padding: 0;
  background-color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 14px;
    transform: rotate(90deg);
  }
}

.basicInfoSocial {
  color: var(--quinary-color);
  margin: 0;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  @include media-breakpoint-up($lg) {
    span {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.linkedinIcon {
  margin-left: 10px;
}

.btnWebsite {
  background-color: var(--primary-color);
  padding: 11px 40px;
  color: var(--secondary-color);
  border-radius: 4px;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  transition: 0.3s ease-in-out;
  text-align: center;
  margin-top: 15px;
  display: inline-block;
  align-self: flex-start;
  text-transform: uppercase;

  &:hover {
    opacity: 0.7;
  }
}

.buttonToggle {
  background-color: var(--primary-color);

  path {
    fill: var(--secondary-color);
  }
}

.openedDescription {
  svg {
    transform: rotate(270deg);
  }
}
