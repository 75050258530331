@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

.errorPage {
  width: 100vw;
  min-height: 100vh;
  padding: 0px;
  text-align: -webkit-center;
  background-color: $black;
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  align-content: center;
  font-size: 1rem;

  @include media-breakpoint-up($md) {
    font-size: 2rem;
  }
}

h1 {
  color: $white;
  border-bottom: solid;
}

h5 {
  color: $white;
}

.errorText {
  width: 80%;
  text-align: center;
  color: $light_gray;
  font-weight: bolder;
}
