@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

.searchQuestionsWrapper {
  padding: 0px 18px;

  @include media-breakpoint-up($xl) {
    margin-bottom: 10px;
  }
}

@media only screen and (max-height: 700px) {
  input,
  textarea {
    font-size: 1.6rem !important;
  }

  input::placeholder,
  textarea::placeholder {
    font-size: 1.4rem;
  }
}

.searchQuestions {
  width: 100%;
  border-radius: 100px;
  height: 50px;
  border: 0;
  padding: 8px 16px;
  background-color: rgba(var(--primary-colorA), 0.08);
  color: var(--quaternary-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.7px;

  &.safariOlderVersioninput {
  }

  &::placeholder {
    color: var(--quaternary-color);
  }

  &:focus {
    outline: none;
  }
}

.inputFieldSafari {
  margin-top: 60px;
  -webkit-appearance: textfield; //Safari
}
