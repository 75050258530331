@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

.videoContainer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

video {
  object-fit: contain;
  -o-object-fit: contain;
  position: relative;
  left: 50%;
  width: 100vh;
  height: 100%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.videoWrapper {
  width: 100%;
  height: calc(var(--vh) * 100);
  position: relative;
  display: flex;
  overflow: hidden;
}

.videoHolder {
  width: 100%;
  height: 100%;
  position: relative;
  transition: 0.3s ease-in-out;

  @include media-breakpoint-up($lg) {
    height: auto;
  }
}

.videoHolderActive {
  @include media-breakpoint-size($md) {
    width: calc(100% - 33%);
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  color: $white;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 100%;
  z-index: 4;
  align-items: center;
  bottom: 150px;
  max-width: calc(100% - 110px);

  @include media-breakpoint-up($xl) {
    max-width: 74%;
  }
}

.subtitles {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $white;
  z-index: 10;
  text-shadow: 0px 0px 5px rgba($black, 0.8);

  @include media-breakpoint-up($xl) {
    font-size: 30px;
    line-height: 34px;
  }
}

.videoControlsHolder {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 4;
}

.videoControlsBtn {
  width: 29px;
  height: 29px;
  background-color: var(--primary-color);
  border-radius: 5px 5px 0px 0px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  cursor: pointer;

  svg {
    height: 13px;
    transform: translateY(2px) rotate(-90deg);
    -webkit-transform: translateY(2px) rotate(-90deg);
    transition: 0.3s ease-in-out;
  }

  path {
    fill: var(--secondary-color);
  }

  @include media-breakpoint-size($md) {
    width: 34px;
    height: 34px;
  }
}

.openedControls {
  bottom: 28px;

  .videoControlsBtn {
    svg {
      transform: translateY(2px) rotate(90deg);
      -webkit-transform: translateY(2px) rotate(90deg);
    }
  }
}

.videoSrc {
  top: 0;
  position: absolute;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);

  @include media-breakpoint-up($xl) {
    //   height: auto; this causes glitch on Safari
    position: relative;

    &:not(.videoSrcMain) {
      position: relative;
    }
  }
}

.videoSrcMain {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
}

.activeVideo {
  overflow: hidden;

  .videoSrcMain {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
    z-index: 2;
  }
}

.startExperienceButton {
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 2;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 15px;
  color: #000;
  background-color: #eee;

  @include media-breakpoint-up($md) {
    padding: 13px 40px;
    bottom: 50px;
  }
}
