@mixin media-breakpoint-up($val) {
  @media only screen and (min-width: $val) {
    @content;
  }
}

@mixin media-breakpoint-down($val) {
  @media only screen and (max-width: $val) {
    @content;
  }
}

@mixin media-breakpoint-between($max, $min) {
  @media only screen and (max-width: $max) and (min-width: $min) {
    @content;
  }
}

@mixin media-breakpoint-size($val) {
  @media only screen and (min-width: $val) and (min-height: $val) {
    @content;
  }
}
