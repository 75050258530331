@import 'src/styles/config/variables';

.main {
  display: block;
  min-width: 100%;
  margin: 0;
  background-color: $black;
  padding: 0;
}

.holder {
  width: 100%;
  max-width: 100vh;
  position: relative;
  margin: 0 auto;
}
