@import 'src/styles/config/mixins';
@import 'src/styles/config/variables';

.btnFS {
  position: absolute;
  right: 0;
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
  padding: 10px;
  top: 29px;
  border-radius: 4px 0px 0px 4px;
  z-index: 99;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: var(--secondary-color);
  }

  .questionsClosedWrapper {
    display: flex;
    color: var(--secondary-color);
  }

  &.openedQuestionsButton {
    border-radius: 0 4px 4px 0;
    left: 0;
    right: auto;
    width: 44px;
    height: 44px;
  }

  svg {
    place-self: center;
  }

  @include media-breakpoint-size($md) {
    height: 62px;
  }

  @include media-breakpoint-size($md) {
    &.openedQuestionsButton {
      border-radius: 4px 0px 0px 4px;
      left: auto;
      right: 33%;
      width: 62px;
      height: 62px;
    }
  }
}

.btnFS:hover {
  opacity: 0.7;
}

@include media-breakpoint-down($md) {
  .questionTogglerAnimated {
    animation: pulse 2s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--primary-color);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
