@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

.corporateLogo {
  position: absolute;
  bottom: 65px;
  left: 0;
  z-index: 3;
  background-color: var(--tertiary-color);
  padding: 5px 10px;
  border-radius: 0px 100px 100px 0px;
  text-decoration: none;
  max-width: 95px;

  &:hover {
    opacity: 0.7;
  }

  @include media-breakpoint-up($lg) {
    bottom: 50px;
    padding: 7px 12px;
    max-width: 120px;
  }
}

.corporateImg {
  display: block;
  fill: $white;
  max-width: 100%;
}
