@import '../../styles/config/variables';

.questionList {
  overflow: hidden;
  overflow-y: scroll;
  list-style: none;
  padding: 0px 18px 10px 18px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: $gray $transparent;
}

.questionListHolder {
  position: relative;
  overflow: hidden;
  height: 100%;
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: 10px;
}
