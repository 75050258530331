@import 'src/styles/config/variables';
@import 'src/styles/config/mixins';

* {
  box-sizing: border-box;
}

.basicInfo {
  position: absolute;
  top: 0;
  left: 0;
  width: min-content;
  z-index: 4;

  @include media-breakpoint-size($xs) {
    width: auto;
  }

  &:not(.opened) {
    .btn {
      svg {
        height: 38px;
        width: auto;
      }
    }
  }
}

.basicHolder {
  position: relative;
  display: none;
}

.btn {
  position: absolute;
  right: -44px;
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
  padding: 10px;
  top: 29px;
  border-radius: 0 4px 4px 0;
  z-index: 99;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: var(--secondary-color);
  }

  &:hover {
    opacity: 0.7;
  }

  @include media-breakpoint-size($md) {
    right: -62px;
    width: 62px;
    height: 62px;
  }
}

.opened {
  .basicHolder {
    display: inline-flex;
  }

  .btn {
    width: 42px;
    right: -42px;

    svg {
      transform: rotate(-180deg);
    }
  }
}
